<template>
  <v-text-field
    v-if="visible"
    v-model="value"
    :label="label"
    :error-messages="meta.dirty || meta.touched ? errors : []"
    type="phone"
    @blur="handleBlur"
  >
    <template #prepend>
      <v-select
        v-model="country"
        label="Country"
        :items="countries"
        hide-details
        class="mt-n4 mr-n3"
      />
    </template>

    <template #append-inner>
      <mtf-field-icon
        :meta="meta"
        :has-errors="hasErrors"
      />
    </template>
  </v-text-field>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import { useMatterificField } from '#imports';
import parsePhoneNumber, { getCountries } from 'libphonenumber-js';
import { isString, map } from 'lodash-es';
import ct from 'countries-and-timezones';
import MtfFieldIcon from '../FieldIcon.vue';

export default defineComponent({
  name: 'MtfFieldPhone',
  components: { MtfFieldIcon },
  inheritAttrs: true,
  customOptions: {},
  // ----------------
  props: {
    schema: { type: Object, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    visible: { type: Boolean, default: true }
  },
  //emits: ['update:modelValue', 'input',],
  // ----------------

  setup(props, context) {
    const { meta, value, errors, handleBlur, validate, hasErrors } = useMatterificField(
      props.name,
      props.schema,
      context
    );
    const getFlagEmoji = (countryCode) =>
      String.fromCodePoint(...[...countryCode.toUpperCase()].map((x) => 0x1f1a5 + x.charCodeAt()));

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const countries = map(getCountries(), (code) => ({
      value: code,
      title: `${code}\xa0\xa0${getFlagEmoji(code)}`
    }));

    const country = ref(ct.getCountryForTimezone(timezone)?.id || 'US');

    const safeValue = computed({
      get() {
        const phoneNumber = isString(value.value) ? value.value : value.value?.number || '';
        return phoneNumber;
      },
      set(val) {
        const phoneNumber = parsePhoneNumber(val, country.value);
        const payload = {
          countryCode: phoneNumber?.country || country.value,
          countryCallingCode: phoneNumber?.countryCallingCode,
          number: phoneNumber?.number || val,
          national: phoneNumber?.nationalNumber,
          uri: phoneNumber?.getURI(),
          whatsappUri: phoneNumber?.isValid()
            ? `https://wa.me/${phoneNumber.countryCallingCode}${phoneNumber.nationalNumber}`
            : null
        };

        value.value = payload;
      }
    });

    return {
      meta,
      value: safeValue,
      valueParsed: value,
      errors,
      handleBlur,
      validate,
      countries,
      country,
      hasErrors
    };
  },

  watch: {
    country() {
      // Force revalidation of the phone number when the country changes
      // We do this by resetting the value to the national number, falling back to the full number
      // this will trigger the computed value to update
      // and the full phone number will be re-parsed and validated against the new country
      const phoneNumber = this.valueParsed?.national || this.value;
      this.value = '';
      this.value = phoneNumber;
    }
  }
});
</script>
